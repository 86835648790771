@import '~@/assets/styles/components/modal-cadastro';
@import '~@/assets/styles/components/formularios';

.modal-extrato-credito-titulo {
    display: flex;
    padding: 0 36px 5px;
    font-size: 1em;
    color: #0091ff;
    text-transform: uppercase;
    font-weight: bold;

    &-titulo {
        width: 100%;
    }

    &-total {
        width: 50%;
        text-align: end;
    }
}